<template>
  <div class="parking-block">
    <div class="block-header">
      <!-- <h6 class="title">{{ floor.floor }} ряд</h6> -->
      <div>
        <div class="block-title" v-if="!isBlock">Парковка {{block.entrance}}</div>
        <div class="block-title" v-if="isBlock">Парковка блока {{block.block}}</div>
        <div class="free-parking">Пустых мест: {{ block.free }}</div>
      </div>
      <v-menu offset-y v-show="showEditEntrance">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
          class="dots-open"
          v-bind="attrs"
          v-on="on"
          >
            <img src="@/assets/images/dots.svg" alt="dots" />
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
          <!-- <div class="edit" @click="toggleEditEntrance">Редактировать</div> -->
          <div class="delete" @click="toggleDeleteEntrance">Удалить</div>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <hr style="height: 2px; color: #F0F0F3; border-width: 0px; background-color:#F0F0F3; margin: 5px 0px 30px 0px;" >
    <!-- <div class="free-parking">Пустых мест: 76</div>
    <div v-for="(floor, key) in aptMatrix" :key="key" class="parking-row">
      <div class="floor">{{floor.floor}} ряд</div>
      <div
        v-for="apt in floor"
        :key="apt.apartment_id"
        class="parking-item"
        :class="{
          'grey': !!apt.personal_account,
          'yellow': !!apt.owner && apt.owner.debt < 30000,
          'red': !!apt.owner && apt.owner.debt >= 30000 && apt.owner.debt < 100000,
          'dark': !!apt.owner && apt.owner.debt >= 100000,
        }"
        @click="clickOnApt(apt)"
      >{{ apt.number }}</div>
    </div> -->
    <div
      v-for="parkingRow in floors"
      :key="parkingRow.floor"
      class="floor-item"
    >
      <div class="flat-row">
        <div
          v-for="parkingSlot in parkingRow.places"
          :key="parkingSlot.building_id"
          :class="{'grey': parkingSlot.owner}"
          class="flat-num"
          @click="clickOnParkingSlot(parkingSlot)"
        >
          {{parseInt(parkingSlot.number)+(parkingRow.places.length*(parseInt(parkingRow.floor)-1))}}</div>
      </div>
    </div>
    <div v-if="showDeleteEntrance" style="z-index: 10000; position: absolute">
      <DeletionConfirm
        title="Вы действительно хотите удалить этот паркинг?"
        :description="`Паркинг '${block.entrance}'`"
        @close="toggleDeleteEntrance"
        @delete="deleteParking"
      />
    </div>
  </div>
</template>

<script>
import {deleteParking} from "@/api/buildings";

export default {
  components: {
    DeletionConfirm: () => import('@/components/modals/DeletionConfirm.vue'),
  },
  props: {
    floors:{
      type:Array,
      default: () => [],
    },
    block: {
      type: Object,
      default: () => {},
    },
    isExt:{
      type: Boolean,
      default: false,
    },
    isBlock:{
      type: Boolean,
      default: false,
    },
    index:{
      type: Number,
      default: 0
    }
  },
  data: () => ({
    showEditEntrance: false,
    showDeleteEntrance: false,
  }),
  // computed: {
  //   aptMatrix() {
  //     if(this.floor?.apartments){
  //
  //     const apts = this.floor?.apartments?.reduce((acc, cur) => {
  //       let floor = acc;
  //       if (Array.isArray(floor[cur.floor])) {
  //         floor[cur.floor].push(cur);
  //       } else {
  //         floor[cur.floor] = [];
  //         floor[cur.floor].push(cur);
  //       }
  //       return floor;
  //     }, {});
  //     return apts;
  //   }else{
  //       const apts = this.floor?.places?.reduce((acc, cur) => {
  //         let floor = acc;
  //         if (Array.isArray(floor[cur.floor])) {
  //           floor[cur.floor].push(cur);
  //         } else {
  //           floor[cur.floor] = [];
  //           floor[cur.floor].push(cur);
  //         }
  //         return floor;
  //       }, {});
  //       return apts;
  //
  //     }
  //   },
  // },
  mounted() {
    this.$emit("hideSkeleton");
    this.$store.commit("DEL_LOADING", "PARKING_LOADING");
  },
  methods: {
    toggleDeleteEntrance() {
      this.showDeleteEntrance = !this.showDeleteEntrance;
      this.showEditEntrance = false;
    },
    clickOnParkingSlot(parkingSlot) {
      if (!!parkingSlot.owner) {
        this.$emit('showResident', {...parkingSlot,isExt:this.isExt});
      }
      else {
        if(!this.isBlock){
          this.$emit('addResident', { ...parkingSlot, floor:this.block.floor, entrance: this.block.entrance, isExt:this.isExt });
        }
        else{
          this.$emit('addResident',
              { ...parkingSlot, floor:this.block.floor,block: this.block.block, entrance: this.block.entrance, isExt:this.isExt });
        }
      }
    },
    async deleteParking (){
      let params ='';
      if(this.isBlock)
        params = "block="+this.block.block_id;
      else
        params = "entrance="+this.block.entrance;
        this.$emit('init');
      console.log('params',params)
      await deleteParking(params).then(res => {
        if(res.data.success){
          console.log('succes');
          this.$emit('delete');
        }else{
          alert(res.data.message)
          console.log('bad');
        }
      });
      this.$emit('init');
    }
  },
};
</script>

<style>
.edit-entrance{
  user-select: none;
}
</style>
